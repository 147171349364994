import React, { memo, useMemo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { ImageDefinition } from "~/constants";
import { Skeleton } from "~/components/Skeleton";
import styles from "./Image.module.css";
import cx from "classnames";
import { ImageDerivative } from "~/generated";

export interface ImageProps {
  type: "4By3";
  loading?: boolean;
  data?: ImageDefinition;
  className?: string;
}
const makeSourceSet = (def: ImageDefinition) =>
  Object.keys(def)
    .filter((key) => key.match(/^w\d/))
    .reduce<string[]>((acc, currKey) => {
      const { src, width } = def[
        currKey as keyof typeof def
      ] as ImageDerivative;

      return [...acc, `${src} ${width}w`];
    }, [])
    .join(", ");

const Image_ = ({ loading, data, className }: ImageProps) => {
  const wrapperClasses = cx({
    [styles.image]: true,
    [styles.wrapper]: true,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    [className || ""]: !!className,
  });
  const loadingIndicator = useMemo(
    () => (
      <span className={wrapperClasses}>
        <Skeleton
          className={cx({
            [styles.skeleton]: true,
            [styles.wrapper]: true,
          })}
        />
      </span>
    ),
    [wrapperClasses]
  );
  const srcSet = useMemo(() => data && makeSourceSet(data), [data]);

  if (loading || typeof data === "undefined") {
    return loadingIndicator;
  }

  return (
    <LazyLoadImage
      draggable={false}
      useIntersectionObserver
      wrapperClassName={wrapperClasses}
      placeholder={loadingIndicator}
      src={data.w1000.src ? data.w1000.src : undefined}
      srcSet={srcSet}
      // TODO: Make this configurable
      sizes="calc(100vw - var(--margin-lg) * 2)"
      alt={data?.alt}
    />
  );
};

export const Image = memo(Image_);
